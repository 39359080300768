<template>
  <div class="base-container">
    <div class="rule-wrapper">
      <template v-if="!showEdit">
        <!--header-mol-->
        <div class="header-mol">
          <div class="info">
            <div class="title">基本信息</div>
          </div>
          <div class="action">
            <el-button size="small" @click="showEdit = true">修改</el-button>
          </div>
        </div>
        <!--header-mol end-->
        <div class="base-info mt20">
          <p>
            合作期限：
            <span class="red">
              {{
                info.termStart
                  ? `${info.termStart} 至 ${info.termEnd}`
                  : "未设置"
              }}
            </span>
          </p>
          <p v-if="info.takeInterval !== null || info.reportProtect !== null">
            报备时间要求：
            <span v-if="info.takeInterval !== null">
              提前 <span class="red">{{ `${info.takeInterval}分` }}</span
              >报备
            </span>
            <span
              v-if="info.takeInterval !== null && info.reportProtect !== null"
              >，</span
            >
            <span v-if="info.reportProtect !== null">
              保护时间
              <span class="red">{{
                info.reportProtect == 0 ? "不保护" : `${info.reportProtect}天`
              }}</span>
            </span>
          </p>
          <p v-if="info.takeProtect !== null">
            带看保护时间：
            <span class="red">{{
              info.takeProtect == 0 ? "不保护" : `${info.takeProtect}天`
            }}</span>
          </p>
        </div>
        <!--header-mol-->
        <div class="header-mol mt30">
          <div class="info">
            <div class="title">详细规则</div>
          </div>
        </div>
        <!--header-mol end-->
        <div class="btn-list mt30">
          <span v-for="(item, key, index) in formMaps" :key="index">
            <el-button
              plain
              :type="activeIndex === index ? 'primary' : 'info'"
              v-if="item.hasContent"
              @click="activeIndex = index"
            >
              {{ item.label }}
            </el-button>
          </span>
        </div>
        <div v-for="(item, key, index) in formMaps" :key="index">
          <div
            class="list mt20"
            v-show="activeIndex === index"
            v-if="item.hasContent"
          >
            <div v-for="(value, c_key, c_idx) in item.maps" :key="c_idx">
              <dl v-if="info[key] && info[key][c_key]">
                <dt>{{ value }}：</dt>
                <dd
                  v-html="
                    info[key]
                      ? info[key][c_key].replace(/(\r|\n)+/g, '<br>')
                      : ''
                  "
                ></dd>
              </dl>
            </div>
          </div>
        </div>
      </template>
      <edit-rule
        :info="info"
        :formMaps="formMaps"
        :show.sync="showEdit"
        @refresh="getRulesDetail"
      ></edit-rule>
    </div>
  </div>
</template>

<script>
import { rulesDetail } from "@/api/distribution";
import EditRule from "./_components/edit";
export default {
  components: { EditRule },
  data() {
    return {
      info: {},
      showEdit: false,
      activeIndex: 0, // 当前显示规则索引
      formMaps: {
        detailRule: {
          // 报备/带看
          active: 0,
          label: "报备/带看",
          maps: {
            visitTake: "注意事项",
            contentRequire: "内容要求",
            receptionTime: "案场接待时间",
            takeProcess: "带看流程",
            revisitPrinciple: "复访原则"
          },
          hasContent: true
        },
        judgeCustomer: {
          // 判客/确客
          active: 0,
          label: "判客/确客",
          maps: {
            sameGroupCustomer: "同组客户",
            singleCollision: "撞单判客",
            trueGuest: "甲方确客"
          },
          hasContent: true
        },
        visitIdentification: {
          // 顺访认定
          active: 0,
          label: "顺访认定",
          maps: {
            stage: "顺访保护期",
            redRange: "红线范围",
            customerCognizance: "客户认定"
          },
          hasContent: true
        },
        finishedPin: {
          // 成销/结佣
          active: 0,
          label: "成销/结佣",
          maps: {
            purchaseProcess: "认购流程",
            pinRule: "结佣规则"
          },
          hasContent: true
        }
      },
      formArr: []
    };
  },
  created() {
    this.getRulesDetail();
  },
  methods: {
    async getRulesDetail() {
      const res = await rulesDetail();
      if (res) {
        this.info = res;
        let keys = [
          "detailRule",
          "judgeCustomer",
          "visitIdentification",
          "finishedPin"
        ];
        keys.forEach(key => {
          this.formMaps[key].hasContent = Object.values(this.info[key]).some(
            item => item
          );
        });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.rule-wrapper {
  width: 730px;
  margin: 0 auto;
  .btn-list {
    .el-button {
      margin-right: 10px;
    }
  }
}
.base-info {
  background-color: #fef5f5;
  border-radius: 5px;
  padding: 10px 20px;
  p {
    color: #8a8a8a;
    line-height: 200%;
  }
}
.list {
  dl {
    display: flex;
    line-height: 180%;
    & + dl {
      margin-top: 20px;
    }
    dt {
      width: 70px;
      margin-right: 15px;
      color: #aaa;
    }
    dd {
      flex: 1;
    }
  }
}
</style>
