<template>
  <el-form v-show="show">
    <!--header-mol-->
    <div class="header-mol">
      <div class="info">
        <div class="title">基本信息</div>
      </div>
      <div class="action">
        <el-button size="small" @click="cancel">取消</el-button>
        <el-button type="primary" size="small" @click="save">保存</el-button>
      </div>
    </div>
    <!--header-mol end-->
    <div class="form-list mt20">
      <div class="cell">
        <div class="title">合作期限<span>（不对外展示）</span></div>
        <el-form-item>
          <el-date-picker
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            v-model="termDate"
            clearable
            @change="setTermDate"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="cell">
        <div class="title">
          报备带看间隔<span>（录入“0”表示不需要间隔）</span>
        </div>
        <el-form-item prop="takeInterval">
          <el-input placeholder="请输入" v-model="formData.takeInterval">
            <span slot="suffix" class="unit">分钟</span>
          </el-input>
        </el-form-item>
      </div>
      <div class="cell">
        <div class="title">报备保护期<span>（录入“0”表示不保护）</span></div>
        <el-form-item prop="reportProtect">
          <el-input placeholder="请输入" v-model="formData.reportProtect">
            <span slot="suffix" class="unit">天</span>
          </el-input>
        </el-form-item>
      </div>
      <div class="cell">
        <div class="title">带看保护期<span>（录入“0”表示不保护）</span></div>
        <el-form-item prop="takeProtect">
          <el-input placeholder="请输入" v-model="formData.takeProtect">
            <span slot="suffix" class="unit">天</span>
          </el-input>
        </el-form-item>
      </div>
    </div>
    <!--header-mol-->
    <div class="header-mol mt10">
      <div class="info">
        <div class="title">详细规则</div>
      </div>
    </div>
    <!--header-mol end-->
    <div
      class="rule-form-item"
      v-for="(item, key, index) in formMaps"
      :key="key"
    >
      <div class="title">{{ index + 1 }}、{{ item.label }}</div>
      <el-button
        v-for="(value, c_key, c_idx) in item.maps"
        :key="c_key"
        plain
        :type="
          item.active === c_idx
            ? 'primary'
            : formData[key][c_key]
            ? 'success'
            : 'info'
        "
        @click="item.active = c_idx"
      >
        <strong
          class="el-icon-check"
          v-if="item.active !== c_idx && formData[key][c_key]"
        ></strong>
        {{ value }}
      </el-button>
      <div v-for="(value, c_key, c_idx) in item.maps" :key="c_idx" class="mt20">
        <el-form-item v-if="item.active === c_idx">
          <el-input
            type="textarea"
            rows="6"
            :placeholder="`请输入${value}`"
            v-model="formData[key][c_key]"
          ></el-input>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import { editRules } from "@/api/distribution";
let formData = {
  reportProtect: "", // 报备保护期
  takeInterval: "", // 报备带看间隔
  takeProtect: "", // 带看保护期
  termEnd: "", // 合作期限结束
  termStart: "", // 合作期限开始
  detailRule: {
    // 报备/带看
    contentRequire: "", // 内容要求
    receptionTime: "", // 案场接待时间
    revisitPrinciple: "", // 复访原则
    takeProcess: "", // 带看流程
    visitTake: "" // 报备带看
  },
  finishedPin: {
    // 成销/结佣
    pinRule: "", // 结佣规则 ,
    purchaseProcess: "" // 认购流程
  },
  judgeCustomer: {
    sameGroupCustomer: "", //同组客户
    singleCollision: "", // 撞单判客 ,
    trueGuest: "" // 甲方确客
  },
  visitIdentification: {
    customerCognizance: "", // 客户认定 ,
    redRange: "", // 红线范围 ,
    stage: "" // 顺访保护期
  }
};
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => ({})
    },
    formMaps: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: this.$deepClone(formData),
      termDate: ""
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(bool) {
        if (this.info.id) {
          this.formData = { ...this.info };
        } else {
          this.formData = this.$deepClone(formData);
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$emit("update:show", false);
    },
    async save() {
      const res = await editRules(this.formData);
      if (res) {
        this.cancel();
        this.$emit("refresh");
        this.$showSuccess("保存成功");
      }
    },
    setTermDate(val) {
      // 合作期限起止时间
      let [start, end] = val || [];
      this.formData.termEnd = start;
      this.formData.termStart = end;
    }
  }
};
</script>

<style lang="scss" scoped>
.rule-form-item {
  margin-top: 30px;
  .title {
    font-size: 16px;
    color: #8a8a8a;
    margin-bottom: 15px;
  }
}
.form-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .cell {
    width: 316px;
    .title {
      font-size: 14px;
      color: #666;
      margin-bottom: 12px;
      span {
        color: #aaa;
      }
    }
  }
  .el-input {
    width: 100%;
  }
  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner {
    width: 100%;
  }
  .unit {
    color: #333;
    margin-right: 5px;
  }
}
</style>
